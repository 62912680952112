import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/ihs-api/auth/login';

const authSatuSehatApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    authLoginSatuSehat: build.mutation<
      {
        providerId: string;
      },
      {
        providerId: string;
      }
    >({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useAuthLoginSatuSehatMutation } = authSatuSehatApi;
